<!--
 * @Descripttion:
 * @version:
 * @Author: 李宗哲
 * @Date: 2020-04-15 11:08:52
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-05-27 19:25:24
 -->
<template>
  <div class="concent">
    <div style="height: 100%;">
      <div class="team-rentop" id="teamRentop">
        <div style="box-shadow: 0.02rem 0.02rem 0.4rem #f6f6f6;padding-bottom: 0.3rem;">
          <div class="ahead">
            <span class="team-name">{{empname}}</span>
            <span class="team-empno">{{empno}}</span>
          </div>
          <div class="situation">
            <span class="left">加入时间</span>
            <span class="right">{{indate?indate:'  '}}</span>
          </div>
          <div class="situation">
            <span class="left">累计订单数量</span>
            <span class="right">{{ddnum}}个</span>
          </div>
          <div class="situation">
            <span class="left">累计出单保费</span>
            <span class="right">{{mount}}元</span>
          </div>
          <div class="situation">
            <span class="left">累计贡献推荐奖励</span>
            <span class="rmb">{{tjmount}}元</span>
          </div>
        </div>
      </div>
      <div style="width: 100%;height: 0.3rem;background-color: #F9F9F9;"></div>
      <div class="team-ren">
        <div class="ahead" id="aHead">
          <span class="prod-date">日期</span>
          <div class="rqdate" @click="isDatePickerShow=true">
            <img src="@/assets/nh/nhwxdate.png" alt class="bottomimg" />
            <span class="prodtime">{{datetime}}</span>
            <img src="@/assets/nh/nhwx-date01.png" alt class="topimg" />
          </div>
        </div>
        <div
          :style="'height:'+(height-titleHeight-tHeight-10)+'px;padding-bottom: 1rem;'"
          v-show="!isNull"
        >
          <md-scroll-view
            class="scroll"
            ref="scrollView"
            auto-reflow
            :scrolling-x="false"
            @end-reached="loadMorechange"
          >
            <div class="cpname" v-for="(item,index) in List" :key="index">
              <div class="situattap">
                <span class="content">{{item.prodname}}</span>
              </div>
              <div class="situation">
                <span class="left">生效时间</span>
                <span class="right">{{item.appdate}}</span>
              </div>
              <div class="situation">
                <span class="left">保费</span>
                <span class="right">{{item.mount}}元</span>
              </div>
              <div class="situation" style="border-bottom: 0.01rem solid rgb(240, 240, 240);">
                <span class="left">推荐奖励</span>
                <span class="rmb" v-if="item.tjmount">{{item.tjmount}}元</span>
              </div>
            </div>
            <md-scroll-view-more slot="more" :is-finished="listFinished" />
          </md-scroll-view>
        </div>
        <div class="nodata" v-show="isNull">
          <img class="kongimg" src="@/assets/nh/nhwxtdnull.jpg" alt style="width:100%;" />
          <span>-暂无数据-</span>
        </div>
      </div>
    </div>
    <div>
      <md-date-picker
        ref="datePicker2"
        v-model="isDatePickerShow"
        type="custom"
        title="选择日期"
        :min-date="minDate"
        :default-date="defaultDate"
        large-radius
        :custom-types="['yyyy', 'MM']"
        @confirm="onDatePickerConfirm"
      ></md-date-picker>
    </div>
  </div>
</template>

<script>
import loadMorechange from '@/mixins/loadMorechange'
import { Datateammx } from '@/api/agent/agent'
export default {
  mixins: [loadMorechange],
  components: {},
  data () {
    return {
      empname: '',
      empno: '',
      indate: '',
      ddnum: '',
      mount: '',
      tjmount: '',
      minDate: new Date('1940-01'),
      defaultDate: new Date(),
      isDatePickerShow: false,
      datetime: '',
      List: [],
      listpageNo: 1,
      listsize: 9,
      listtotal: 0,
      listtotalPage: 0,
      loadflag: false,
      isNull: true,
      listFinished: false,
      titleHeight: '',
      height: '',
      tHeight: ''
    }
  },
  created () {
    this.height = document.body.clientHeight
    this.empname = this.$route.query.empname
    this.empno = this.$route.query.empno
    this.indate = this.$route.query.indate && this.$route.query.indate == 'undefined' ? '' : this.$route.query.indate
    this.ddnum = this.$route.query.ddnum
    this.mount = this.$route.query.mount
    this.tjmount = this.$route.query.tjmount
    this.getnowdate()
    this.getData()
  },
  updated () {
    var o = document.getElementById('teamRentop')
    var ar = document.getElementById('aHead')
    this.titleHeight = o.clientHeight || o.offsetHeight
    this.tHeight = ar.clientHeight || ar.offsetHeight
  },
  methods: {
    getnowdate () {
      let arr = new Date()
      var year = arr.getFullYear()
      var month = arr.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      this.datetime = year + '-' + month
    },
    onDatePickerConfirm (columnsValue) {
      this.datetime = this.$refs.datePicker2.getFormatDate('yyyy-MM')
      this.listpageNo = 1
      this.listsize = 9
      this.List = []
      this.getData()
    },
    getData () {
      let data = {
        page: this.listpageNo,
        size: this.listsize,
        ddstatus: '1',
        data: this.datetime,
        empno: this.empno
      }
      Datateammx(data).then(res => {
        this.List =
          this.List.length == '0'
            ? res.data.data.rows
            : this.List.concat(res.data.data.rows)

        if (this.List.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
        this.listtotal = res.data.data.total
        this.listtotalPage = res.data.data.totalpage
        // console.log('--------------请求数据中--------------')
        // console.log('请求数据中总页数' + this.listtotalPage)
        // console.log('请求数据中当前页' + this.listpageNo)

        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true
        } else {
          this.listFinished = false
          this.$refs.scrollView.finishLoadMore()
        }

        this.loadflag = true
        // console.log('请求数据中加载状态' + this.loadflag)
        // console.log('请求数据中是否停止加载' + this.listFinished)
      })
    }
  }
}
</script>

<style scoped>
.concent {
  height: 100vh;
  overflow: hidden;
  background-color: #ffffff;
  /* padding-bottom: 1.5rem; */
}
.team-ren {
  margin-bottom: 0.3rem;
  padding: 0.4rem 0.4rem;
  background-color: #ffffff;
}
.team-rentop {
  margin-bottom: 0.3rem;
  padding: 0.4rem 0.4rem;
  background-color: #ffffff;
  /* height: 30vh; */
}
.ahead {
  display: flex;
  justify-content: space-between;
  /* height: 1rem;
  line-height: 1rem; */
  border-bottom: 0.01rem solid rgb(240, 240, 240);
  padding-bottom: 0.48rem;
  padding-top: 0.48rem;
}
.team-name {
  color: #383838;
  font-size: 0.39rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  margin-left: 0.3rem;
}
.team-empno {
  color: #383838;
  font-size: 0.39rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  margin-right: 0.3rem;
}
.situation {
  display: flex;
  justify-content: space-between;
  padding: 26px 0px;
  /* height: 1rem;
  line-height: 1rem; */
}
.left {
  font-family: PingFang-SC-Medium;
  color: #9b9b9b;
  font-size: 0.36rem;
  margin-left: 0.3rem;
}
.right {
  font-family: PingFang-SC-Medium;
  color: #9b9b9b;
  font-size: 0.36rem;
  margin-right: 0.3rem;
}
.rmb {
  font-family: PingFang-SC-Medium;
  color: #fb6821;
  font-size: 0.36rem;
  margin-right: 0.3rem;
}
.rqdate {
  display: flex;
  align-items: center;
  margin-right: 0.3rem;
}
.prod-date {
  color: #8e8e90;
  font-size: 0.39rem;
  font-family: PingFang-SC-Medium;
  margin-left: 0.3rem;
}
.prodtime {
  color: #8e8e90;
  font-size: 0.39rem;
  font-family: PingFang-SC-Medium;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}
.bottomimg {
  width: 0.4rem;
  height: 0.4rem;
}
.topimg {
  width: 0.2rem;
  height: 0.15rem;
}
.content {
  color: #3d3d3d;
  font-size: 0.39rem;
  font-family: PingFang-SC-Bold;
  margin-left: 0.3rem;
  font-weight: bold;
}
.situattap {
  height: 1rem;
  line-height: 1rem;
}
.cpname {
  margin-bottom: 0.3rem;
}
.nodata {
  margin-top: 0.5rem;
  text-align: center;
  color: #c4c4c4;
  font-size: 0.36rem;
  font-family: PingFang-SC-Medium;
}
</style>
